import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

import { ContactoPlano, ContactoProyecto, FormularioInforme } from "../requests";

const PATH = 'formulario';
@Injectable({ providedIn: 'root' })
export class FormularioService {
    _contactoSubject: BehaviorSubject<string> = new BehaviorSubject<string>('/contacto/informes');
    revision: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient
    ) { }

    informes(request: FormularioInforme): Observable<any> {
        return this.http.post<any>(`${PATH}/informes`, request);
    }

    informesProyecto(request: ContactoProyecto): Observable<any> {
        return this.http.post<any>(`${PATH}/informesProyecto`, request);
    }

    informesDepartamento(request: ContactoPlano): Observable<any> {
        return this.http.post<any>(`${PATH}/informesDepartamento2`, request);
    }

    postVenta(request: any): Observable<any> {
        return this.http.post<any>(`${PATH}/postVenta`, request);
    }

    vendeTuTerreno(request: any): Observable<any> {
        return this.http.post<any>(`${PATH}/vendeTuTerreno`, request);
    }

    trabajaConNosotros(request: any): Observable<any> {
        return this.http.post<any>(`${PATH}/trabajaConNosotros`, request);
    }

    cotizacion(request: any): Observable<any> {
        return this.http.post<any>(`${PATH}/cotizacion`, request);
    }
}