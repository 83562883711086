import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { LazyIframeDirective, LazyImageDirective } from './directives';
import { SafePipe, YoutubePipe } from './pipes';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        LazyImageDirective,
        LazyIframeDirective,
        SafePipe,
        YoutubePipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LazyImageDirective,
        LazyIframeDirective,
        SafePipe,
        YoutubePipe
    ]
})
export class SharedModule { }