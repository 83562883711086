import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProyectoResponse, ProyectoAntiguoResponse, DepartamentoProyectoResponse } from '../responses';

const PATH = 'proyecto';
@Injectable({ providedIn: 'root' })
export class ProyectoService {
    private _proyectosSubject: BehaviorSubject<Array<ProyectoResponse>> = new BehaviorSubject<Array<ProyectoResponse>>(null);

    private _proyectos: Array<ProyectoResponse>;
    get proyectos(): Array<ProyectoResponse> { return this._proyectos }

    constructor(private http: HttpClient) {
        this.http.get<Array<ProyectoResponse>>(`${PATH}/1/actuales`).subscribe(proyectos => {
            this._proyectosSubject.next(proyectos);
        });
    }

    get proyectosChange(): Observable<Array<ProyectoResponse>> {
        return this._proyectosSubject.asObservable();
    }

    obtener(): Observable<Array<ProyectoResponse>> {
        return this.http.get<Array<ProyectoResponse>>(`${PATH}/1/actuales`).pipe(
            map(data => {
                this._proyectos = data;
                return data;
            })
        );
    }

    obtenerProyectosPostVenta(): Observable<Array<ProyectoResponse>> {
        return this.http.get<Array<ProyectoResponse>>(`${PATH}/postVenta`);
    }

    obtenerProyectos(id: number): Observable<Array<ProyectoResponse>> {
        return this.http.get<Array<ProyectoResponse>>(`${PATH}/${id}/todos`);
    }

    obtenerDormitorios(id: number): Observable<Array<number>> {
        return this.http.get<Array<number>>(`${PATH}/${id}/dormitorios`);
    }

    obtenerAutos(id: number, dormitorios: number): Observable<Array<number>> {
        return this.http.post<Array<number>>(`${PATH}/${id}/autos`, { dormitorios: dormitorios });
    }

    obtenerDepartamentos(id: number): Observable<Array<DepartamentoProyectoResponse>> {
        return this.http.get<Array<DepartamentoProyectoResponse>>(`${PATH}/${id}/departamentos`);
    }

    obtenerAutosPorDepartamento(id: number): Observable<Array<number>> {
        return this.http.get<Array<number>>(`${PATH}/${id}/autosPorDepartamento`);
    }

    obtenerProyectosTerminados(id: number): Observable<Array<ProyectoAntiguoResponse>> {
        return this.http.get<Array<ProyectoAntiguoResponse>>(`${PATH}/${id}/terminados`);
    }
}