import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { menuTransition, fadeAnimation, showNav } from './animations/animation';
import { ProyectoResponse } from './api/responses';
import { AuthService, FormularioService, MenuStyleService, ProyectoService } from './api/services';

// declare var ga: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [menuTransition, fadeAnimation, showNav]
})

export class AppComponent implements OnInit {
    isBackNavigation = false;
    menu = true;
    status: 'active' | 'inactive' = 'inactive';
    proyectos: ProyectoResponse[] = [];
    telefono: string = '975244600';
    state = 'hidden';

    constructor(
        public menuStyle: MenuStyleService,
        public router: Router,
        private proyectoService: ProyectoService,
        private formularioService: FormularioService,
        public authService: AuthService
    ) {
        this.menuStyle.isBackground.subscribe(value => { value ? this.status = 'inactive' : this.status = 'active'; });
    }

    ngOnInit(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // ga('set', 'page', event.urlAfterRedirects);
                // ga('send', 'pageview');

                this.proyectoService.proyectosChange.subscribe(proyectos => {
                    if (proyectos) {
                        this.proyectos = proyectos;
                        this.telefono = this.proyectos.find(p => event.url.includes(p.router)) ? this.proyectos.find(p => event.url.includes(p.router)).telefono : '975244600';
                    }
                })

                // this.proyectos = this.proyectoService.proyectos;
                // this.telefono = this.proyectos.find(p => event.url.includes(p.router)) ? this.proyectos.find(p => event.url.includes(p.router)).telefono : '975244600';
                // if (event.url.includes('/plano') || event.url.includes('/page/404')) {
                //     this.menu = false;
                // } else if (event.url === '/llano-zapata-464' || event.url === '/inclan-1010' || event.url === '/') {
                //     this.status = 'inactive';
                //     this.menu = true;
                // } else {
                //     this.status = 'active';
                //     this.menu = true;
                // }
                this.status = 'active';
                this.router.navigated = false;
                this.hide();
            }
        });
    }

    toggle(): void {
        this.state = this.state === 'showed' ? 'hidden' : 'showed';
    }

    hide(): void {
        this.state = 'hidden';
    }

    contacto(): void {
        this.formularioService._contactoSubject.next('/contacto/informes');
        this.formularioService.revision.next(true);
    }
}
