import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var MenuStyleService = /** @class */ (function () {
    function MenuStyleService() {
        this.isbackgroundBehaviorSubject = new BehaviorSubject(true);
    }
    Object.defineProperty(MenuStyleService.prototype, "isBackground", {
        get: function () {
            return this.isbackgroundBehaviorSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    MenuStyleService.prototype.setBackground = function (background) {
        this.isbackgroundBehaviorSubject.next(background);
    };
    MenuStyleService.prototype.getBacground = function () {
        return this.isbackgroundBehaviorSubject.getValue();
    };
    MenuStyleService.ngInjectableDef = i0.defineInjectable({ factory: function MenuStyleService_Factory() { return new MenuStyleService(); }, token: MenuStyleService, providedIn: "root" });
    return MenuStyleService;
}());
export { MenuStyleService };
