<nav [ngClass]="{'d-none':!menu}" class="navbar fixed-top navbar-expand-lg navbar-dark nav-principal" [@status]="status">
    <div class="container-fluid padding-especial">
        <a class="navbar-brand" routerLink="/"><img alt="titan" alt="titan" width="150" height="37" src="assets/images/logo-titan.png"></a>
        <div class="d-block d-lg-none">
            <div class="btn-group">
                <a class="btn btn-whatsapp mx-2 rounded d-flex" href="https://wa.me/51{{telefono}}" target="_blank" aria-label="whatsapp"><img alt="" width="20" height="22" class="icon-svg" src="assets/images/whatsapp.svg"></a>
                <a class="btn btn-telefono rounded d-flex" href="tel:+51{{telefono}}" target="_blank" aria-label="telefono"><img alt="" width="20" height="22" class="icon-svg" src="assets/images/phone.svg"></a>
            </div>
        </div>
        <button class="navbar-toggler" type="button" (click)="toggle()">
            <span class="navbar-toggler-icon" aria-label="toggle"><img alt="" class="icon-svg" width="20" src="assets/images/bars.svg"></span>
        </button>
        <div class="navbar-collapse" [@showNav]="state">
            <p class="text-white ml-sm-0 ml-1 mb-0 text-logo d-none d-sm-none d-md-none d-lg-none d-xl-block"><b>{{authService.fecha}} años construyendo espacios donde nos gustaría vivir</b></p>
            <ul class="navbar-nav ml-auto">
                <!-- <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="/">Inicio</a>
                </li>
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" href="https://blog.constructoratitan.com/">Blog</a>
                </li>
                <li class="nav-item d-sm-block d-none" dropdown container="body">
                    <button type="button" class="btn btn-link nav-link dropdown-toggle dropdown-toggle-split" dropdownToggle>Proyectos <span class="caret"></span><span class="sr-only">Split button!</span></button>
                    <div class="dropdown-menu mt-3" *dropdownMenu>
                        <a class="dropdown-item" *ngFor="let proyecto of proyectos" [routerLink]="['',proyecto.router]">{{proyecto.nombre}}</a>
                    </div>
                </li>
                <li class="nav-item d-block d-sm-none" dropdown placement="bottom right">
                    <button type="button" class="btn btn-link mx-auto nav-link dropdown-toggle dropdown-toggle-split" dropdownToggle>Proyectos <span class="caret"></span><span class="sr-only">Split button!</span></button>
                    <div class="dropdown-menu mt-3" *dropdownMenu>
                        <a class="dropdown-item" *ngFor="let proyecto of proyectos" [routerLink]="['',proyecto.router]">{{proyecto.nombre}}</a>
                    </div>
                </li>
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="/servicios">Servicios</a>
                </li>
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="/nosotros">Nosotros</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" routerLink="/contacto/informes" (click)="contacto()">Contáctanos</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" target="_blank" href="http://backend.constructoratitan.com/">Intranet</a>
                </li> -->
                <div class="d-lg-block d-none">
                    <div class="btn-group">
                        <a class="btn btn-whatsapp mx-2 rounded d-flex" href="https://wa.me/51{{telefono}}" target="_blank" aria-label="whatsapp"><img alt="" width="20" class="icon-svg" src="assets/images/whatsapp.svg"></a>
                        <a class="btn btn-telefono rounded d-flex" href="tel:+51{{telefono}}" target="_blank" aria-label="telefono"><img alt="" width="20" class="icon-svg" src="assets/images/phone.svg"></a>
                    </div>
                </div>
                <li class="nav-item d-none d-sm-none d-md-none d-lg-block">
                    <span class="nav-link logo"><img alt="1982" alt="1982" width="56" height="49" src="assets/images/1982.png"></span>
                </li>
            </ul>
        </div>
    </div>
</nav>
<main [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
    <router-outlet #o="outlet"></router-outlet>
</main>
<!-- <div class="footer d-flex justify-content-around flex-md-row flex-column align-items-center bg-titan-azul text-white p-2 py-md-4 py-5">
    <img alt="logo" width="150" height="37" src="assets/images/logo-titan.png" width="150">
    <p class="m-0">Informes y ventas:</p>
    <p class="m-0">(511) 275 4000 / 975 244 600 / Av. Velasco Astete 2123 - Surco, Lima</p>
    <p class="m-0"><small>Todos los derechos registrados a Constructora Titan S.A.</small></p>
</div> -->