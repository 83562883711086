import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PATH = 'formulario';
var FormularioService = /** @class */ (function () {
    function FormularioService(http) {
        this.http = http;
        this._contactoSubject = new BehaviorSubject('/contacto/informes');
        this.revision = new BehaviorSubject(false);
    }
    FormularioService.prototype.informes = function (request) {
        return this.http.post(PATH + "/informes", request);
    };
    FormularioService.prototype.informesProyecto = function (request) {
        return this.http.post(PATH + "/informesProyecto", request);
    };
    FormularioService.prototype.informesDepartamento = function (request) {
        return this.http.post(PATH + "/informesDepartamento2", request);
    };
    FormularioService.prototype.postVenta = function (request) {
        return this.http.post(PATH + "/postVenta", request);
    };
    FormularioService.prototype.vendeTuTerreno = function (request) {
        return this.http.post(PATH + "/vendeTuTerreno", request);
    };
    FormularioService.prototype.trabajaConNosotros = function (request) {
        return this.http.post(PATH + "/trabajaConNosotros", request);
    };
    FormularioService.prototype.cotizacion = function (request) {
        return this.http.post(PATH + "/cotizacion", request);
    };
    FormularioService.ngInjectableDef = i0.defineInjectable({ factory: function FormularioService_Factory() { return new FormularioService(i0.inject(i1.HttpClient)); }, token: FormularioService, providedIn: "root" });
    return FormularioService;
}());
export { FormularioService };
