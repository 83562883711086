import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ApiResponse } from './responses';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.startsWith('http')) {
            return next.handle(request);
        }

        const url = environment.apiEndpoint + request.url;
        request = request.clone({
            url: url,
            headers: request.headers.set('Accept', 'application/json')
        });

        if (request.url.includes('postVenta') || request.url.includes('vendeTuTerreno') || request.url.includes('trabajaConNosotros') || request.url.includes('cotizacion')) {
            if (!request.headers.has('Content-Type') && (request.method === 'PATCH' || request.method === 'POST' || request.method === 'PUT')) {
                request = request.clone({
                    headers: request.headers.delete('Content-Type').set("Accept", "application/json")
                });
            }
        }
        else {
            if (!request.headers.has('Content-Type') && (request.method === 'PATCH' || request.method === 'POST' || request.method === 'PUT')) {
                request = request.clone({
                    headers: request.headers.set('Content-Type', 'application/json')
                });
            }
        }

        return next.handle(request).pipe(
            map((e) => e instanceof HttpResponse ? e.clone({ body: (<ApiResponse>e.body).data }) : e)
        );
    }
}