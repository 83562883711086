import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    { path: '', loadChildren: './modules/home/home.module#HomeModule' },
    { path: '', loadChildren: './modules/paginas/paginas.module#PaginasModule' },
    { path: 'contacto', loadChildren: './modules/contacto/contacto.module#ContactoModule' },
    // { path: ':proyecto', loadChildren: './modules/proyecto/proyecto.module#ProyectoModule' },
    { path: '**', redirectTo: 'page/404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
    exports: [RouterModule]
})
export class AppRouting { }
