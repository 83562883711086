import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PATH = 'proyecto';
var ProyectoService = /** @class */ (function () {
    function ProyectoService(http) {
        var _this = this;
        this.http = http;
        this._proyectosSubject = new BehaviorSubject(null);
        this.http.get(PATH + "/1/actuales").subscribe(function (proyectos) {
            _this._proyectosSubject.next(proyectos);
        });
    }
    Object.defineProperty(ProyectoService.prototype, "proyectos", {
        get: function () { return this._proyectos; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProyectoService.prototype, "proyectosChange", {
        get: function () {
            return this._proyectosSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ProyectoService.prototype.obtener = function () {
        var _this = this;
        return this.http.get(PATH + "/1/actuales").pipe(map(function (data) {
            _this._proyectos = data;
            return data;
        }));
    };
    ProyectoService.prototype.obtenerProyectosPostVenta = function () {
        return this.http.get(PATH + "/postVenta");
    };
    ProyectoService.prototype.obtenerProyectos = function (id) {
        return this.http.get(PATH + "/" + id + "/todos");
    };
    ProyectoService.prototype.obtenerDormitorios = function (id) {
        return this.http.get(PATH + "/" + id + "/dormitorios");
    };
    ProyectoService.prototype.obtenerAutos = function (id, dormitorios) {
        return this.http.post(PATH + "/" + id + "/autos", { dormitorios: dormitorios });
    };
    ProyectoService.prototype.obtenerDepartamentos = function (id) {
        return this.http.get(PATH + "/" + id + "/departamentos");
    };
    ProyectoService.prototype.obtenerAutosPorDepartamento = function (id) {
        return this.http.get(PATH + "/" + id + "/autosPorDepartamento");
    };
    ProyectoService.prototype.obtenerProyectosTerminados = function (id) {
        return this.http.get(PATH + "/" + id + "/terminados");
    };
    ProyectoService.ngInjectableDef = i0.defineInjectable({ factory: function ProyectoService_Factory() { return new ProyectoService(i0.inject(i1.HttpClient)); }, token: ProyectoService, providedIn: "root" });
    return ProyectoService;
}());
export { ProyectoService };
