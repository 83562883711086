import { Directive, ElementRef } from '@angular/core';

@Directive({ selector: 'img' })
export class LazyImageDirective {
    constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
        const supports = 'loading' in HTMLImageElement.prototype;
        if (supports) {
            nativeElement.setAttribute('loading', 'lazy');
        }
    }
}

@Directive({ selector: 'iframe' })
export class LazyIframeDirective {
    constructor({ nativeElement }: ElementRef<HTMLIFrameElement>) {
        const supports = 'loading' in HTMLIFrameElement.prototype;
        if (supports) {
            nativeElement.setAttribute('loading', 'lazy');
        }
    }
}