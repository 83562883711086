import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuStyleService {
    public isbackgroundBehaviorSubject = new BehaviorSubject<boolean>(true);

    get isBackground(): Observable<boolean> {
        return this.isbackgroundBehaviorSubject.asObservable();
    }

    setBackground(background: boolean): void {
        this.isbackgroundBehaviorSubject.next(background);
    }

    getBacground(): boolean {
        return this.isbackgroundBehaviorSubject.getValue();
    }

    constructor() { }
}